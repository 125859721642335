@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght,YTLC@0,6..12,75..125,200..1000,440..540;1,6..12,75..125,200..1000,440..540&display=swap');

.App {
  text-align: center;
  height: 100%;
  background: #f6f7fb;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


* {
  white-space: pre-line;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
